<template>
  <v-row class="bbr-list--completed">
    <v-col v-if="hasListData || isSearching" cols="12">
      <div class="form-container d-flex align-center">
        <div class="search-form">
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            label="Search food entries"
            style="width:400px"
            v-model="searchKey"
            @input="searchChange"
            autocomplete="off"
          />
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <food-list-table
        v-if="type == 2"
        :is-searching="isSearching"
        :list="list"
        :loading="loading"
        :meta="listMeta"
        list-for="Active"
        @loadMore="loadMoreContent"
        @goTo="goTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import FoodListTable from '../components/FoodListTable'
import { mapMutations, mapState, mapActions } from 'vuex'
import Event from '@/services/eventBus'
import { debounce, extend } from 'lodash'

export default {
  name: 'ActiveFoods',

  components: {
    FoodListTable,
  },

  data() {
    return {
      loading: false,
      searchKey: null,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.foods.filter,
      list: (state) => state.foods.completed.list,
      listMeta: (state) => state.foods.completed.listMeta,
    }),

    hasListData() {
      return !!this.list.length
    },

    isSearching() {
      return !!this.searchKey
    },

    type() {
      return this.$route.meta ? this.$route.meta.type : 1
    },
  },

  created() {
    this.setFilter({ completed: true })
    this.clearList()

    if (this.filter.completed) {
      this.searchKey = this.filter.search
    }

    if (this.searchKey) {
      this.fetchListData()
    }

    Event.$on('update-active-foods-list', () => {
      this.setFilter({ completed: true })

      this.clearList()
      this.fetchListData()
    })
  },

  methods: {
    ...mapActions({
      getDataList: 'foods/getDataList',
    }),

    ...mapMutations({
      setFilter: 'foods/setFilter',
      clearList: 'foods/clearList',
    }),

    searchChange(key) {
      this.setFilter({
        search: key,
        completed: true,
      })

      this.searchData()
    },

    searchData: debounce(function() {
      this.clearList()
      this.fetchListData()
    }, 550),

    async fetchListData(page = 1) {
      if (this.loading) return

      let search = this.searchKey
      let completed = 1
      // type: 1 Recipe, 2 Food
      let params = {
        page,
        type: this.type,
        search,
        status: this.$route.meta.status,
      }

      if (this.type === 1) {
        params = extend(params, { completed })
      }

      this.loading = true

      await this.getDataList(params)

      this.loading = false
    },

    loadMoreContent() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListData(this.listMeta.current_page + 1)
      }
    },

    goTo(id) {
      this.$router.push({ name: 'form.food', params: { id } })
    },
  },
}
</script>
